export interface FallBackLanguage {
  code: string;
  name: string;
  nativeName: string;
  isPublished: boolean;
}

export const fallBackLanguages: FallBackLanguage[] = [
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français',
    isPublished: true,
  },
  {
    code: 'it',
    name: 'Italian',
    nativeName: 'Italiano',
    isPublished: true,
  },
  {
    code: 'es',
    name: 'Spanish (Spain)',
    nativeName: 'Español (España)',
    isPublished: true,
  },
  {
    code: 'de',
    name: 'German (Germany)',
    nativeName: 'Deutsch (Deutschland)',
    isPublished: true,
  },
  {
    code: 'pt',
    name: 'Portuguese (Portugal)',
    nativeName: 'Português (Portugal)',
    isPublished: true,
  },
  {
    code: 'nn',
    name: 'Norwegian',
    nativeName: 'Norsk',
    isPublished: true,
  },
  {
    code: 'pl',
    name: 'Polish',
    nativeName: 'Polski',
    isPublished: true,
  },
  {
    code: 'nl',
    name: 'Dutch',
    nativeName: 'Nederlands',
    isPublished: true,
  },
  {
    code: 'da',
    name: 'Danish',
    nativeName: 'Dansk',
    isPublished: true,
  },
  {
    code: 'deAT',
    name: 'German (Austria)',
    nativeName: 'Deutsch (Österreich)',
    isPublished: true,
  },
  {
    code: 'fi',
    name: 'Finnish',
    nativeName: 'Suomi',
    isPublished: true,
  },
  {
    code: 'sv',
    name: 'Swedish',
    nativeName: 'Svenska',
    isPublished: true,
  },
  {
    code: 'nlBE',
    name: 'Dutch (Belgium)',
    nativeName: 'Nederlands (België)',
    isPublished: true,
  },
  {
    code: 'ja',
    name: 'Japanese',
    nativeName: '日本語',
    isPublished: true,
  },
  {
    code: 'en',
    name: 'English (US)',
    nativeName: 'English (US)',
    isPublished: true,
  },
  {
    code: 'zhCN',
    name: 'Chinese (Simplified)',
    nativeName: 'Chinese (Simplified)',
    isPublished: true,
  },
  {
    code: 'ptBR',
    name: 'Portuguese (Brazil)',
    nativeName: 'Portuguese (Brazil)',
    isPublished: true,
  },
];
