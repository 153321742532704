import i18next from 'i18next';
import capitalize from 'lodash/capitalize';

import { IS_ALPHA_NUMERIC, IS_UUID } from './regex';

export const specialCaseSentences = {
  off_boarding_fee: i18next.t('routes.titles.people.specialCaseSentences.offboardingFee'),
  // ... more cases where value comes with wrong underscore placements...
};

export type SpecialCaseSentences = typeof specialCaseSentences;

export const pluralString = (str: string, amount: number): string => {
  if (amount === 1) {
    return str;
  }
  const reg = /.*(ch|sh|s|x|z){1}$/;
  return `${str}${reg.test(str) ? 'es' : 's'}`;
};

export const convertSnakeCaseToSentence = (str: string, shouldCapitalize: boolean = true) => {
  return (
    specialCaseSentences[str as keyof SpecialCaseSentences] ||
    ((shouldCapitalize && capitalize(str)) || str).replaceAll('_', ' ')
  );
};

export const replaceUnderscoreAndCapitalize = (inputString: string): string => {
  const lowerCaseStringWithSpaces = inputString.replace(/_/g, ' ').toLowerCase();
  const capitalizedString = lowerCaseStringWithSpaces.charAt(0).toUpperCase() + lowerCaseStringWithSpaces.slice(1);

  return capitalizedString;
};

export const convertToTitleCase = (field: string) => {
  if (!field || typeof field !== 'string') {
    return '';
  }

  // Split the string into words by spaces, underscores, or camel case
  const words = field
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .trim()
    .split(/\s+/);

  // Capitalize the first letter of each word and join them with spaces
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  const result = capitalizedWords.join(' ');

  return result;
};

// checks a string if contains non Latin-1 characters
export const isISO88591 = (string: string): boolean => {
  for (let i = 0; i < string.length; i++) {
    if (string.charCodeAt(i) > 255) {
      return false;
    }
  }
  return true;
};

const WHITE_LIST_ALPHA_NUMERIC = ['tax1099', 'g2n'];

const CUSTOM_URL_PATTERNS = [{ origin: 'form_builder/uploads/', normalized: 'form_builder/uploads/:alphaNumeric' }];

// Normalize the URL to group similar routes
export const normalizeApiUrl = (url?: string): string => {
  if (!url) {
    return '';
  }

  const customUrl = CUSTOM_URL_PATTERNS.find((u) => url.includes(u.origin));

  if (customUrl) {
    return customUrl.normalized;
  }

  return url
    .replace(IS_UUID, ':uuid')
    .replace(IS_ALPHA_NUMERIC, (match) => (WHITE_LIST_ALPHA_NUMERIC.includes(match) ? match : ':alphaNumeric'));
};

export const normalizeURL = (input: string): string => {
  if (!input) {
    throw new Error('URL cannot be empty');
  }

  let url = input.trim();
  if (!/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }

  return new URL(url).href;
};

export function truncateString(text?: string, count = 60) {
  if (!text) {
    return '';
  }
  const hasSingleWord = text.split(' ').length === 1;
  if (!hasSingleWord || text.length <= count) {
    return text;
  }

  return `${text.substring(0, count)}...`;
}

/**
 * Remove special characters that conflicts with Regex
 * @param {*} text
 * @returns text without the special characters
 */
export const sanitizeText = (text: string) => {
  if (text) {
    return text.replace(/[.*+?^=!:${}()|[\]/\\]/g, '');
  }

  return text;
};
