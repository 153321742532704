export const URL_REGEX = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

export const STRICT_URL_REGEX =
  // eslint-disable-next-line eslint-comments/no-restricted-disable
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b)(?!\.)([-\/\p{Letter}\p{Mark}0-9()@:%;_\+.~#?&//=]*)$/u;

export const NOT_STRICT_URL_REGEX =
  // eslint-disable-next-line eslint-comments/no-restricted-disable
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b)(?!\.)([-\/\p{Letter}\p{Mark}0-9()@:%;_\+.~#?&//=]*)$/u; // http(s) and www. are optional

export const HTTP_PROTOCOL_REGEX = /^https?:\/\/.+/i;

export const isEmail = (email = '') => {
  let regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const IS_EU_VAT_NUMBER_REGEX =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const IS_UUID = /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/g;
export const IS_ALPHA_NUMERIC = /\b[a-zA-Z]*\d+[a-zA-Z\d]*\b/g;
