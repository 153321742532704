// translation:no-need
import { appConfig } from '@letsdeel/constants';
import { URL_REGEX } from '@letsdeel/utils';
import * as Sentry from '@sentry/react';
// Polyfill for CSSStyleSheet
import 'construct-style-sheets-polyfill';
import 'core-js/actual/array/to-reversed';
// polyfill to support toSorted and toSortedArray
import 'core-js/actual/array/to-sorted';
// Polyfill for Promise.withResolvers
import 'core-js/actual/promise/with-resolvers';
// polyfill to support structuredClone
import 'core-js/actual/structured-clone';
import 'core-js/es/array/at';
import 'core-js/es/string/at';
// Polyfills to support .replaceAll and .at
import 'core-js/es/string/replace-all';
import log from 'loglevel';
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';

import { DYNAMIC_RENDER_DEBUG_KEY } from '@/components/DynamicRender/DynamicRender.constants';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import { PERMISSIONS_DEBUG_KEY } from '@/context/Authorization/constants';
import { AppLaunchStatusUtil } from '@/native/AppLaunchStatus/AppLaunchStatus';
import { handleBackButton } from '@/native/General/nativeBackButtonHandler';
import { persistLocalStorageHandler } from '@/native/General/persistLocalStorageHandler';
import { isNative, isReactNativeApp } from '@/utils/crossPlatform';

import { RELEASE_NAME, SITE_IS_DOWN } from './constants/main';
import { loadDynamicTranslations, loadFixedTranslations } from './i18n/languageLoader';
import ReactNativeEmitter from './native/reactNative/ReactNativeEmitter';
// CON-6828 Override Yup email validation to match BE validation
import './utils/emailValidation';

const LoadingScreen = lazy(() => import('./components/LoadingScreen/LoadingScreen'));

window.log = log;
log.setDefaultLevel((localStorage.getItem('LOG_LEVEL') || 'warn').toLowerCase());

const changeStage = (newStage) => {
  localStorage.setItem('VITE_STAGE', newStage);
  location.reload();
};

window._dev = () => {
  changeStage('development');
};

window._local = () => {
  changeStage('local');
};

window._giger = (gigerEnv) => {
  if (!gigerEnv) {
    console.warn("You didn't provide a giger env");
    return;
  }
  const isURL = gigerEnv.match(URL_REGEX);
  changeStage(isURL ? gigerEnv : `https://api-${gigerEnv}.giger.training`);
};

const PERMISSIONS_DEBUG_OPTIONS = ['orgSets', 'teamSets', 'allSets', 'noSets', 'permissionSets'];

window._permissionsDebug = (...args) => {
  if (args.length === 0 && localStorage.getItem(PERMISSIONS_DEBUG_KEY)) {
    localStorage.removeItem(PERMISSIONS_DEBUG_KEY);
  } else {
    localStorage.setItem(PERMISSIONS_DEBUG_KEY, JSON.stringify(args.length === 0 ? ['noSets'] : args));
  }
  // eslint-disable-next-line no-console
  console.log('Permissions debug mode is now', localStorage.getItem(PERMISSIONS_DEBUG_KEY) ? 'ON' : 'OFF');
  // eslint-disable-next-line no-console
  console.log(`Possible options: `, PERMISSIONS_DEBUG_OPTIONS);
  location.reload();
};

window._dynamicRenderDebug = () => {
  if (localStorage.getItem(DYNAMIC_RENDER_DEBUG_KEY)) {
    localStorage.removeItem(DYNAMIC_RENDER_DEBUG_KEY);
  } else {
    localStorage.setItem(DYNAMIC_RENDER_DEBUG_KEY, 'true');
  }
  // eslint-disable-next-line no-console
  console.log('Dynamic render debug mode is now ', localStorage.getItem(DYNAMIC_RENDER_DEBUG_KEY) ? 'ON' : 'OFF');
  location.reload();
};

window.DEEL_VERSION = import.meta.env.VITE_CI_COMMIT_SHA ? RELEASE_NAME : 'unknown';

window.forceCrash = () => {
  setTimeout(() => {
    throw new Error('Simulated Crash');
  }, 0);
};

if (isReactNativeApp()) {
  window.onMessageFromRN = (message) => {
    try {
      const { action, payload } = message;
      ReactNativeEmitter.emit(action, payload);
    } catch (error) {
      console.error('Error handling message in ReactNativeEmitter:', error);
    }
  };
}

const IGNORED_ERRORS = [
  'amplitude is not defined',
  `Can't find variable: amplitude`,
  'Event `Event` (type=error) captured as promise rejection',
  'Network Error',
  'AxiosError: Network Error',
  'AxiosError: Request failed with status code 401',
  'AxiosError: Request failed with status code 403',
  'AbortError: AbortError', // sentry own error
  'Request aborted',
];

const SENTRY_ENVS = {
  development: 'dev',
  production: 'prod',
  demo: 'demo',
  staging: 'demo',
  'dev-dr': 'dev',
};

function buildSentryEnv() {
  const STAGE = window.STAGE;

  if (Object.keys(SENTRY_ENVS).includes(STAGE) && SENTRY_ENVS[STAGE]) {
    return SENTRY_ENVS[STAGE];
  }

  return 'dev';
}

Sentry.init({
  release: RELEASE_NAME,
  dsn: appConfig.sentryDsn,
  // HOTFIX: wrong sentry environment set in build
  // TODO: fix/change how pipeline works to set this with env variables
  // environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  environment: buildSentryEnv(),
  integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
  tracesSampleRate: appConfig.sentryEnvironment === 'prod' ? 0.25 : 0,
  ignoreErrors: ['Non-Error exception captured with keys'],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  beforeSend: (event, hint) => {
    const error = hint.originalException;
    const isIgnoredError = IGNORED_ERRORS.some((item) => error?.message?.toLowerCase().includes(item.toLowerCase()));

    if (error?.message && isIgnoredError) {
      return null;
    }

    return event;
  },
});

const Loader = () => {
  return (
    <Suspense fallback={null}>
      <LoadingScreen show={true} isAppRoot />
    </Suspense>
  );
};

const renderErrorPage = async (err, root) => {
  root.render(
    <ErrorBoundary fallback={null}>
      <Loader />
    </ErrorBoundary>
  );
  console.error('Sorry. There was a problem loading domain config.', err);
};

const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = async () => {
  const fromServer = await loadFixedTranslations();
  await import('./i18n');
  const initApp = (await import('./initApp')).default;
  await initApp(root);
  if (fromServer) {
    requestAnimationFrame(loadDynamicTranslations);
  }
};

const start = async () => {
  if (SITE_IS_DOWN) {
    const Maintenance = (await import('./scenes/Maintenance/Maintenance')).default;
    root.render(<Maintenance />);
  } else {
    (async () => {
      try {
        await renderApp(root);
      } catch (err) {
        renderErrorPage(err, root);
      }
    })();
  }
};

if (!isNative()) {
  // For web - start
  start();
} else {
  AppLaunchStatusUtil.registerKeys();
  if (localStorage.getItem('LS_INTACT') !== 'true') {
    // For native app - if needed - first load local storage from persistent storage.
    // Local storage is fetched when code is imported, so reload is needed to apply changes
    persistLocalStorageHandler().then(() => {
      localStorage.setItem('LS_INTACT', 'true');
      location.reload();
    });
  } else {
    // For native app - start
    handleBackButton();
    start();
  }
}
