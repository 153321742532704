export interface AppConfig {
  packageVersion: string;
  tinymceApiKey: string;
  wikiUrl: string;
  rootsAppUrl: string;
  zavvyAppUrl: string;
  hofyLocal: string;
  ciCommitSha: string;
  mobileBuild: string;
  wikiToken: string;
  whiteLabel: string;
  helpDeskAllowedDomain: string;
  sentryDsn: string;
  sentryEnvironment: string;
  testApiUrl: string;
  sardineClientId: string;
  sardineEnvironment: SardineEnvironment;
  sardineRegion: SardineRegion;
}

type SardineEnvironment = 'sandbox' | 'production';
type SardineRegion = 'default' | 'eu';

export const appConfig: AppConfig = {
  packageVersion: import.meta.env.PACKAGE_VERSION,
  tinymceApiKey: import.meta.env.VITE_TINYMCE_API_KEY,
  wikiUrl: import.meta.env.VITE_WIKI_URL,
  rootsAppUrl: import.meta.env.VITE_ROOTS_APP_URL,
  zavvyAppUrl: import.meta.env.VITE_ZAVVY_APP_URL,
  hofyLocal: import.meta.env.VITE_HOFY_LOCAL,
  ciCommitSha: import.meta.env.VITE_CI_COMMIT_SHA,
  mobileBuild: import.meta.env.VITE_MOBILE_BUILD,
  wikiToken: import.meta.env.VITE_WIKI_TOKEN,
  whiteLabel: import.meta.env.VITE_WHITE_LABEL,
  helpDeskAllowedDomain: import.meta.env.VITE_HELP_DESK_ALLOWED_DOMAIN,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  sentryEnvironment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  testApiUrl: import.meta.env.VITE_TEST_API_URL,
  sardineClientId: import.meta.env.VITE_SARDINE_CLIENT_ID,
  sardineEnvironment: import.meta.env.VITE_SARDINE_ENVIRONMENT,
  sardineRegion: import.meta.env.VITE_SARDINE_REGION,
};
