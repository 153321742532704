export const SITE_IS_DOWN = false && !+localStorage.getItem('maintenance_override'); // Switching this to true will display the "Down for maintenance" screen

export const IS_COOKIE_TOKEN =
  window.reactNative?.isEnabled && window.reactNative?.mode === 'debug' ? false : window.IS_COOKIE_TOKEN;

export const VERSION_KEY_PREFIX = 'APP_VERSION_';

export const RELEASE_NAME = `${import.meta.env.VITE_CI_COMMIT_SHA}${
  import.meta.env.VITE_MOBILE_BUILD ? '-mobile' : ''
}`;

export const DEEL_TERMS_OF_SERVICE_URL = 'https://www.deel.com/terms-of-service';
