import { EventEmitter } from 'events';

import type { FromNativeActionName, FromNativeActions } from './types';

class ReactNativeEmitter extends EventEmitter {
  private static instance: ReactNativeEmitter;

  private constructor() {
    super();
  }

  static getInstance(): ReactNativeEmitter {
    if (!this.instance) {
      this.instance = new ReactNativeEmitter();
    }
    return this.instance;
  }

  public on<T extends FromNativeActionName>(action: T, callback: (payload: FromNativeActions[T]) => void): this {
    return super.on(action, callback);
  }

  public off<T extends FromNativeActionName>(action: T, callback: (payload: FromNativeActions[T]) => void): this {
    return super.off(action, callback);
  }

  public emit<T extends FromNativeActionName>(action: T, payload: FromNativeActions[T]): boolean {
    return super.emit(action, payload);
  }
}

export default ReactNativeEmitter.getInstance();
