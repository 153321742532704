import i18n from 'i18next';
import merge from 'lodash/merge';

import { type FallBackLanguage, fallBackLanguages } from '@/hooks/localization/constants';

import { type Translation } from './types/Translation';

declare global {
  interface Window {
    DEEL_VERSION: string;
    translations: Record<string, Translation>;
    languageHandler: (forceReload?: boolean) => void;
    _toggleShowTranslationKeys: () => void;
    API_URL: string; // Make sure `API_URL` is declared in the global scope
  }
}

/**
 * Returns the current language (default = 'en') and an optional version string
 */
function getLanguageAndVersion() {
  const currentLanguage = localStorage.getItem('language') || 'en';
  const version = window.DEEL_VERSION;
  const versionStr = version !== 'unknown' ? `?v=${version}` : '';
  return { currentLanguage, versionStr };
}

/**
 * Basic JSON fetch helper. Throws if the response is invalid or contains errors.
 */
async function loadFromUrl(url: string, options?: RequestInit) {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`Response status: ${response.status}`);
  }
  const data = await response.json();
  if (!data || data.errors) {
    throw new Error(`No valid translations found in: ${url}`);
  }
  return data;
}

/**
 * Loads fixed translations and adds them to the global window.translations.
 */
export async function loadFixedTranslations() {
  const { currentLanguage, versionStr } = getLanguageAndVersion();
  document.documentElement.setAttribute('lang', currentLanguage);
  if (currentLanguage === 'en') {
    return;
  }

  const match = fallBackLanguages.find((languageObj: FallBackLanguage) => languageObj.code === currentLanguage);
  if (!match) {
    return;
  }

  try {
    const data = await loadFromUrl(`${window.API_URL}/translations/frontend/${currentLanguage}?chunk=fixed`, {
      cache: 'default',
    });
    window.translations = data;
    return true;
  } catch {
    const data = await loadFromUrl(`/translations/${currentLanguage}.json${versionStr}`, { cache: 'default' });
    window.translations = data;
    return false;
  }
}

/**
 * Loads dynamic translations asynchronously after the vendor code and added to i18n resources.
 */
export async function loadDynamicTranslations() {
  const { currentLanguage } = getLanguageAndVersion();
  if (currentLanguage === 'en') {
    return;
  }

  try {
    const data = await loadFromUrl(`${window.API_URL}/translations/frontend/${currentLanguage}?chunk=dynamic`, {
      cache: 'default',
    });
    const mergedData = window.translations ? merge(window.translations, data) : data;
    i18n.addResourceBundle(currentLanguage, 'translation', mergedData, true, true);
    i18n.changeLanguage(currentLanguage); // force re-render of components with translations
  } catch (error) {
    console.error('Failed to load dynamic translations:', error);
  }
}
